<template>
  <div class="page_container">
    <div class="container">
      <el-card shadow="always">
        <div class="enterprise_title">
          <div class="enterprise_title_left">
            <div>
              <img
                v-if="headDataFrom.companyLogo"
                class="enterprise_title_left_img"
                :src="headDataFrom.companyLogo"
              />
              <img
                v-else
                class="enterprise_title_left_img"
                src="../../../assets/image/Group 3237.png"
              />
            </div>
            <div class="enterprise_title_left_content">
              <div class="enterprise_title_left_title">{{ headDataFrom.companyFullName }}</div>
              <div class="enterprise_title_left_container">
                <el-tag
                  v-for="(item, index) in newLabelList"
                  :key="index"
                  size="mini"
                  effect="plain"
                  class="enterprise_title_left_container_tag"
                >
                  <span v-if="index < 3">{{ item }}</span>
                </el-tag>
                <el-popover placement="right" width="400" trigger="hover">
                  <div>
                    <el-tag
                      v-for="(item, index) in headDataFrom.labelList"
                      :key="index"
                      size="mini"
                      effect="plain"
                      style="margin: 5px"
                    >
                      {{ item }}
                    </el-tag>
                  </div>
                  <el-tag
                    v-if="headDataFrom.labelList > 3"
                    slot="reference"
                    size="mini"
                    effect="plain"
                    class="enterprise_title_left_container_tag"
                  >
                    {{ headDataFrom.labelList.length }}+
                  </el-tag>
                </el-popover>
              </div>
              <div class="enterprise_title_left_footer">
                <div class="enterprise_title_left_footer_text">{{ headDataFrom.legalPerson }}</div>
                <div class="enterprise_title_left_footer_text">{{ headDataFrom.companyPhone }}</div>
                <div class="enterprise_title_left_footer_text">
                  {{ headDataFrom.enterpriseAddress }}
                </div>
                <div>
                  <el-link type="primary" :href="headDataFrom.officialWebsite">{{
                    headDataFrom.officialWebsite
                  }}</el-link>
                </div>
              </div>
            </div>
          </div>
          <div class="enterprise_title_right">
            <el-button @click="goAnalysis" type="primary">返回上一级</el-button>
          </div>
        </div>
      </el-card>
      <div class="enterprise_center">
        <div class="enterprise_center_sort">
          <div style="margin-right: 40px">
            该企业推送以下<span style="color: #448aff">{{ total }}</span
            >条内容
          </div>
          <div
            v-for="(item, index) in orderList"
            :key="index"
            class="enterprise_center_sort_center"
          >
            {{ item.name }}
            <div class="main">
              <span
                class="arrUp"
                @click="clickUp1(item)"
                :style="{ 'border-bottom-color': item.color1 }"
              ></span>
              <span
                class="arrDown"
                @click="clickDown1(item)"
                :style="{ 'border-top-color': item.color2 }"
              ></span>
            </div>
          </div>
        </div>
        <div
          v-loading="loading"
          element-loading-text="加载中..."
          element-loading-spinner="el-icon-loading"
          class="enterprise_center_data"
        >
          <el-card
            style="margin-bottom: 10px"
            v-for="(item, index) in detailsTableData"
            :key="index"
          >
            <div class="enterprise_center_data_list">
              <div class="enterprise_center_data_list_left">
                <div class="enterprise_center_data_list_left_img">
                  <div>
                    <img class="enterprise_center_data_list_left_image" :src="item.coverImages" />
                  </div>
                  <!-- <span class="generalVersion">通用版</span> -->
                  <!-- <span class="regionVersion">地域版</span> -->
                </div>
                <div class="enterprise_center_data_list_left_text">
                  <div class="enterprise_center_data_list_left_name">
                    <div>{{ item.informationName }}</div>
                    <div
                      class="enterprise_center_data_list_left_name_right"
                      v-if="item.informationChannel == 1"
                    >
                      <img
                        class="enterprise_center_data_list_left_name_img"
                        src="../../../assets/image/Group 3298.png"
                        alt=""
                      /><span class="enterprise_center_data_list_left_name_text">平台内容</span>
                    </div>
                    <div
                      class="enterprise_center_data_list_left_name_right"
                      v-if="item.informationChannel == 2"
                    >
                      <img
                        class="enterprise_center_data_list_left_name_img"
                        src="../../../assets/image/展厅-5 3.png"
                        alt=""
                      /><span class="enterprise_center_data_list_left_name_text">渠道内容</span>
                    </div>
                  </div>
                  <div class="enterprise_center_data_list_left_type">
                    内容类型:&nbsp;&nbsp;
                    <span v-if="item.informationType == 1">应用场景</span>
                    <span v-if="item.informationType == 2">数智技术</span
                    ><span v-if="item.informationType == 3">竞情</span
                    ><span v-if="item.informationType == 4">案列</span>
                    <span v-if="item.informationType == 5">产品</span>
                    <span v-if="item.informationType == 6">服务商</span>
                  </div>
                  <div class="enterprise_center_data_list_left_label">
                    <el-tag
                      size="mini"
                      effect="plain"
                      style="margin-right: 10px"
                      v-for="(o, index) in itemLabelList(item.labelList)"
                      :key="index"
                      >{{ o }}</el-tag
                    >
                    <el-popover placement="right" width="400" trigger="hover">
                      <div>
                        <el-tag
                          v-for="(i, index) in item.labelList"
                          :key="index"
                          size="mini"
                          effect="plain"
                          style="margin: 5px"
                        >
                          {{ i }}
                        </el-tag>
                      </div>
                      <el-tag
                        size="mini"
                        effect="plain"
                        slot="reference"
                        style="margin-right: 10px"
                        v-if="item.labelList.length > 3"
                        >{{ item.labelList.length }}+</el-tag
                      >
                    </el-popover>
                  </div>
                  <div class="enterprise_center_data_list_left_time">
                    推送时间：{{ item.pushTime }}
                  </div>
                </div>
              </div>
              <!-- <div class="enterprise_center_data_list_center">
                <el-button style="font-size: 20px" type="text">3</el-button>
                <div>用户数</div>
              </div> -->
              <div class="enterprise_center_data_list_right">
                <div class="enterprise_center_data_list_right_top">
                  <el-button
                    style="font-size: 20px"
                    type="text"
                    v-if="item.viewCount > 0"
                    @click="viewNumDialog(item)"
                    >{{ item.viewCount }}</el-button
                  >
                  <div style="font-size: 20px; padding: 15px 0" v-else>0</div>
                  <div>用户数查看数</div>
                </div>
                <div class="enterprise_center_data_list_right_top">
                  <el-button
                    style="font-size: 20px"
                    type="text"
                    v-if="item.shareCount > 0"
                    @click="shareNumDialog(item)"
                    >{{ item.shareCount }}</el-button
                  >
                  <div style="font-size: 20px; padding: 15px 0" v-else>0</div>
                  <div>用户数分享数</div>
                </div>
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </div>
    <!-- 分页区域 -->
    <div class="new_page">
      <el-pagination
        :page-sizes="[4, 10, 30]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!-- 用户查看数弹窗 -->
    <el-dialog :visible.sync="viewNumDialogVisible" width="70%" center>
      <span slot="title" class="custom-dialog-title">
        <div class="left-text">用户查看数</div>
        <span class="center-text">{{ viewNumTitle }}</span>
      </span>

      <div>
        <el-table
          :header-cell-style="{ background: '#F8F9FB', color: '#595959', 'text-align': 'center' }"
          :data="viewNumTableData"
          style="width: 100%; background-color: #f5f7fb"
          @sort-change="changeViewNumOrders"
        >
          <el-table-column type="index" align="center" label="编号" width="80"> </el-table-column>
          <el-table-column prop="realName" align="center" label="姓名"> </el-table-column>
          <el-table-column align="center" label="性别">
            <template v-slot="{ row }">
              <div v-if="row.sex == 2">女</div>
              <div v-else>男</div>
            </template>
          </el-table-column>
          <el-table-column prop="position" align="center" label="职位"> </el-table-column>
          <el-table-column prop="phone" align="center" label="手机号"> </el-table-column>
          <el-table-column prop="address" align="center" label="所在城市"> </el-table-column>
          <el-table-column sortable prop="checkTime" align="center" label="查看日期">
            <template v-slot="{ row }">
              <div>{{ row.checkTime | date }}</div>
              <div>{{ row.checkTime | time }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="new_page">
          <el-pagination
            :page-sizes="[4, 10, 30]"
            :page-size="queryInfo.pageSizeSon"
            layout="total, sizes, prev, pager, next, jumper"
            :total="viewNumTotal"
            @size-change="viewNumHandleSizeChange"
            @current-change="viewNumHandleCurrentChange"
          />
        </div>
      </span>
    </el-dialog>
    <!-- 用户分享数弹窗 -->
    <el-dialog :visible.sync="shareNumDialogVisible" width="70%" center>
      <span slot="title" class="custom-dialog-title">
        <div class="left-text">用户分享数</div>
        <span class="center-text">{{ shareNumTitle }}</span>
      </span>

      <div>
        <el-table
          :header-cell-style="{ background: '#F8F9FB', color: '#595959', 'text-align': 'center' }"
          :data="shareNumTableData"
          style="width: 100%; background-color: #f5f7fb"
          @sort-change="changeShareNumOrders"
        >
          <el-table-column type="index" align="center" label="编号" width="80"> </el-table-column>
          <el-table-column prop="realName" align="center" label="姓名"> </el-table-column>
          <el-table-column align="center" label="性别">
            <template v-slot="{ row }">
              <div v-if="row.sex == 2">女</div>
              <div v-else>男</div>
            </template>
          </el-table-column>
          <el-table-column prop="position" align="center" label="职位"> </el-table-column>
          <el-table-column prop="phone" align="center" label="手机号"> </el-table-column>
          <el-table-column prop="address" align="center" label="所在城市"> </el-table-column>
          <el-table-column sortable prop="createTime" align="center" label="分享日期">
            <template v-slot="{ row }">
              <div>{{ row.createTime | date }}</div>
              <div>{{ row.createTime | time }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="new_page">
          <el-pagination
            :page-sizes="[4, 10, 30]"
            :page-size="queryInfo.pageSizeSon"
            layout="total, sizes, prev, pager, next, jumper"
            :total="shareNumTotal"
            @size-change="shareNumHandleSizeChange"
            @current-change="shareNumHandleCurrentChange"
          />
        </div>
      </span>
    </el-dialog>
  </div>
</template>
  
<script>
import {
  companyDetail,
  selectCompanyAnalysisInformation,
  selectInformationAnalysisUserViewPage,
  selectInformationAnalysisUserSharePage
} from '@/api/analysis.js'
const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10,
  companyId: '', // 企业主键
  informationId: '', // 资讯主键
  informationType: '1', // 1应用场景，2数智技术，3行业动态，4案例视频，5数智产品，6服务商,7政策解读,8组织人才,9专家观点,10数智案例,11场景知识
  orders: '1', // 企业主键
  queryConditions: '', // 模糊搜索资讯名称
  type: 1, // 1=日，2=周，3=月，4=年
  weekNum: '', //周
  time: '',
  typePage: 4, //列表；1=日，2=周，3=月，4=年
  timePage: '', //列表；头部；时间
  weekNumPage: '', //列表；列表；第多少周
  dateType: 1, // 数据类型；1=内容，2=企业，3=顾问
  pageNumSon: 1, // 弹窗当前页
  pageSizeSon: 10 // 弹窗条数
})
export default {
  name: 'enterpriseDetails',
  data() {
    return {
      headDataFrom: {
        labelList: []
      }, // 头部数据
      loading: true,
      queryInfo: { ...defaultQueryInfo }, //查询参数
      detailsTableData: [], //详情表格数据
      total: 0,
      orderList: [
        {
          name: '按推送时间',
          color1: '',
          color2: '#C6C6C6',
          asc: 2,
          desc: 3
        },
        {
          name: '按内容类型',
          color1: '',
          color2: '#C6C6C6',
          asc: 4,
          desc: 5
        }
      ], //排序
      viewNumDialogVisible: false, // 用户查看数弹窗
      shareNumDialogVisible: false, // 用户分享数弹窗
      viewNumTableData: [{}], // 用户查看数列表
      viewNumTitle: '', // 用户查看数列表标题
      viewNumTotal: 0, // 用户查看数列表条数
      shareNumTableData: [{}], // 用户分享数列表
      shareNumTitle: '', // 用户分享数列表标题
      shareNumTotal: 0 // 用户分享列表条数
    }
  },
  computed: {
    archiveId() {
      //存在是编辑 不存在为新增
      return this.$route.query.archiveId
    },
    newLabelList() {
      return this.headDataFrom.labelList.slice(0, 3)
    }
  },
  created() {
    this.queryInfo.companyId = this.archiveId
    this.headSearch()
    this.dataSearch()
  },
  methods: {
    // 返回上一页
    goAnalysis() {
      this.$router.go(-1)
    },
    // 头部详情
    async headSearch() {
      const { data: res } = await companyDetail({ id: this.archiveId })
      if (res.resultCode === 200) {
        this.headDataFrom = res.data
        if (!res.data.labelList) {
          this.headDataFrom.labelList = []
        }
      } else {
        this.$message.error('获取失败！')
      }
    },
    // 列表详情
    async dataSearch() {
      const { data: res } = await selectCompanyAnalysisInformation(this.queryInfo)
      if (res.resultCode === 200) {
        this.loading = false
        this.detailsTableData = res.data.list
        this.total = res.data.total
      } else {
        this.$message.error('获取失败！')
      }
    },
    // 获取用户查看数信息
    async viewNumSearch() {
      const { data: res } = await selectInformationAnalysisUserViewPage(this.queryInfo)
      if (res.resultCode === 200) {
        this.viewNumTableData = res.data.list
        this.viewNumTotal = res.data.total
      } else {
        this.$message.error('获取失败！')
      }
    },
    // 获取用户分享数信息
    async shareNumSearch() {
      const { data: res } = await selectInformationAnalysisUserSharePage(this.queryInfo)
      if (res.resultCode === 200) {
        this.shareNumTableData = res.data.list
        this.shareNumTotal = res.data.total
      } else {
        this.$message.error('获取失败！')
      }
    },

    // 循环标签
    itemLabelList(val) {
      return val.slice(0, 3)
    },

    // 打开用户查看数弹窗
    viewNumDialog(row) {
      console.log(row)
      this.viewNumDialogVisible = true
      this.viewNumTitle = row.companyFullName
      this.queryInfo.informationId = row.id
      this.queryInfo.type = ''
      this.queryInfo.weekNum = ''
      this.queryInfo.time = ''
      this.queryInfo.typePage = ''
      this.queryInfo.weekNumPage = ''
      this.queryInfo.timePage = ''
      this.viewNumSearch()
    },
    // 打开用户分享数弹窗
    shareNumDialog(row) {
      this.shareNumDialogVisible = true
      this.shareNumTitle = row.companyFullName
      this.queryInfo.informationId = row.id
      this.queryInfo.type = ''
      this.queryInfo.weekNum = ''
      this.queryInfo.time = ''
      this.queryInfo.typePage = ''
      this.queryInfo.weekNumPage = ''
      this.queryInfo.timePage = ''
      this.shareNumSearch()
    },

    //升序排序
    clickUp1(item) {
      this.queryInfo.orders = "2"
      this.dataSearch()
      item.color1 = '#0099ff'
      item.color2 = ''
    },
    //降序排序
    clickDown1(item) {
      this.queryInfo.orders = "1"
      this.dataSearch()
      item.color1 = ''
      item.color2 = '#0099ff'
    },
    // 用户查看数排序
    changeViewNumOrders(val) {
      if (val.prop == 'checkTime') {
        if (val.order == 'ascending') {
          //pushCompanyNum列升序时
          this.queryInfo.orders = "2"
          this.viewNumSearch()
        } else if (val.order == 'descending') {
          //pushCompanyNum列降序时
          this.queryInfo.orders = "1"
          this.viewNumSearch()
        } else {
          this.queryInfo.orders = ''
          this.viewNumSearch()
        }
      }
    },
    // 用户分享数排序
    changeShareNumOrders(val) {
      if (val.prop == 'createTime') {
        if (val.order == 'ascending') {
          //pushCompanyNum列升序时
          this.queryInfo.orders = "2"
          this.shareNumSearch()
        } else if (val.order == 'descending') {
          //pushCompanyNum列降序时
          this.queryInfo.orders = "1"
          this.shareNumSearch()
        } else {
          this.queryInfo.orders = ''
          this.shareNumSearch()
        }
      }
    },

    //监听pagesize变化
    handleSizeChange(newSize) {
      this.loading = true
      this.queryInfo.pageSize = newSize
      this.dataSearch()
    },
    //监听页码变化
    handleCurrentChange(newCurrent) {
      this.loading = true
      this.queryInfo.pageNum = newCurrent
      this.dataSearch()
    },
    //监听用户查看数pagesize变化
    viewNumHandleSizeChange(newSize) {
      this.queryInfo.pageSizeSon = newSize
      this.viewNumSearch()
    },
    //监听用户查看数页码变化
    viewNumHandleCurrentChange(newCurrent) {
      this.queryInfo.pageNumSon = newCurrent
      this.viewNumSearch()
    },
    //监听用户分享数pagesize变化
    shareNumHandleSizeChange(newSize) {
      this.queryInfo.pageSizeSon = newSize
      this.shareNumSearch()
    },
    //监听用户分享数页码变化
    shareNumHandleCurrentChange(newCurrent) {
      this.queryInfo.pageNumSon = newCurrent
      this.shareNumSearch()
    }
  }
}
</script>
  
<style lang="less" scoped>
.page_container {
  width: 100%;
  overflow: hidden;
  .container {
    height: calc(100vh - 150px);
    overflow: auto;
    &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
    .enterprise_title {
      height: 100px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .enterprise_title_left {
        display: flex;
        width: 70%;
        height: 100%;
        align-items: center;
        .enterprise_title_left_img {
          width: 100px;
          height: 100px;
          border: none;
        }
        .enterprise_title_left_content {
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          .enterprise_title_left_title {
            height: 40px;
            font-size: 16px;
            font-weight: 800;
          }
          .enterprise_title_left_container {
            height: 40px;
            .enterprise_title_left_container_tag {
              margin-right: 10px;
            }
          }
          .enterprise_title_left_footer {
            display: flex;
            .enterprise_title_left_footer_text {
              margin-right: 20px;
              font-size: 14px;
              color: #585555;
            }
          }
        }
      }
    }
    .enterprise_center {
      margin-top: 40px;
      .enterprise_center_sort {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .enterprise_center_sort_center {
          display: flex;
          align-items: center;
          margin-right: 30px;
          font-size: 14px;
          color: #585555;
          .main {
            display: flex;
            flex-direction: column;
          }
          .arrUp {
            width: 0;
            height: 0;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
            border-bottom: 8px solid #c6c6c6;
            cursor: pointer;
          }
          .arrDown {
            width: 0;
            height: 0;
            margin-top: 2px;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
            border-top: 8px solid #c6c6c6;
            cursor: pointer;
          }
        }
      }
      .enterprise_center_data {
        margin: 20px;
        width: 1605px;
        .enterprise_center_data_list {
          height: 100px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .enterprise_center_data_list_left {
            width: 1000px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .enterprise_center_data_list_left_img {
              height: 120px;
              .enterprise_center_data_list_left_image {
                background: #585555;
                height: 120px;
                width: 180px;
                border-radius: 18px;
              }
              .generalVersion {
                position: relative;
                top: -112px;
                background: #448aff;
                border-radius: 2px;
                padding: 5px 10px 5px 10px;
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                margin: 0 10px;
                color: #ffffff;
              }
              .regionVersion {
                position: relative;
                top: -112px;
                background: #15c53b;
                border-radius: 2px;
                padding: 5px 10px 5px 10px;
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                margin: 0 10px;
                color: #ffffff;
              }
            }
            .enterprise_center_data_list_left_text {
              margin-left: 10px;
              display: flex;
              // align-items: center;
              flex-direction: column;
              .enterprise_center_data_list_left_name {
                // height: 30px;
                // margin-bottom: 5px;
                font-size: 18px;
                font-weight: 550;
                display: flex;
                align-items: center;
                .enterprise_center_data_list_left_name_right {
                  margin-left: 10px;
                  display: flex;
                  align-items: center;
                }
                .enterprise_center_data_list_left_name_img {
                  width: 18px;
                  height: 15px;
                }
                .enterprise_center_data_list_left_name_text {
                  width: 48px;
                  font-size: 12px;
                  font-weight: 300;
                  margin-left: 5px;
                }
              }
              .enterprise_center_data_list_left_type {
                margin: 10px 0;
              }
              .enterprise_center_data_list_left_label {
                height: 35px;
              }
              .enterprise_center_data_list_left_time {
                height: 15px;
                font-size: 12px;
                color: #bebebe;
              }
            }
          }
          .enterprise_center_data_list_center {
            padding: 0 50px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-around;
            height: 80px;
            border-right: 1px solid #bebebe67;
          }
          .enterprise_center_data_list_right {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-around;
            .enterprise_center_data_list_right_top {
              padding: 0 50px;
              display: flex;
              align-items: center;
              flex-direction: column;
              justify-content: space-around;
              height: 80px;
            }
          }
        }
      }
    }
  }
}
.new_page {
  margin: 20px auto;
  width: 100%;
  text-align: center;
}
</style>